import React from "react";
import {Col, Row} from "react-bootstrap";
import {ReactComponent as LogoComponent} from "./icons/logo.svg";


const Header = () => {
    return (
        <Row>
            <Col xl={{span: 12}} className="d-flex justify-content-center align-content-center" >
                <div className="logo__container" >
                    <LogoComponent className="neon__svg logo__ligth logo" />
                    <LogoComponent className="neon__svg logo__ligth logo" />
                </div>
            </Col>
        </Row>
    )
}

export default Header;
