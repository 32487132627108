import {Container} from "react-bootstrap";
import "./App.scss"
import Header from "./component/header";
import Footer from "./component/footer";


function App() {
  return (
    <div className="App">
        <Container>
            <Header/>
            <Footer/>
        </Container>
    </div>
  );
}

export default App;
