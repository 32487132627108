import React, {useEffect, useState} from "react";
import {ButtonGroup, Col, Container, Row} from "react-bootstrap";
import {Facebook, Instagram, EnvelopeOpen} from "react-bootstrap-icons";
import {useWindowSize} from "../misc/window_resize";

const LinkButton = ({link, icon}) => {
    return (
        <a className="btn btn-secondary" href={link} target="_blank" rel="noreferrer">{icon}</a>
    )
}
const Footer = () => {
    const windowSize = useWindowSize();
    const [iconSize, setIconSize] = useState(64);


    useEffect(() => {
        const {width} = windowSize;
        if (width < 640) {
            setIconSize(32);
        } else if (width < 1200) {
            setIconSize(64)
        } else if (width < 2000) {
            setIconSize(96)
        } else {
            setIconSize(128)
        }

    }, [windowSize])

    return (
        <footer className="fixed-bottom">
            <Container>
                <Row className="p-5">
                    <Col xl={{span: 12}} className="text-center">
                        <ButtonGroup >
                            <LinkButton link={"https://facebook.com/productionlovers"} icon={<Facebook size={iconSize}/>}/>
                            <LinkButton link={"https://instagram.com/productionlovers"} icon={<Instagram size={iconSize}/>}/>
                            <LinkButton link={"mailto:productionlovers@email.cz"}
                                  icon={<EnvelopeOpen size={iconSize}/>}/>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;